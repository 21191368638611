<script setup lang="ts">
import { useDropZone } from "@vueuse/core";

const emits = defineEmits(["fileUpload"]);
const props = defineProps({
  allowedFileType: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  disableDrop: {
    type: Boolean,
    default: false,
  },
});

const id = ref(Math.random().toString(36));
const inputRef = ref<HTMLDivElement>();

const handleFileUpload = ($event: Event) => {
  const target = $event.target as HTMLInputElement;
  const files = target.files;
  if (files !== null) {
    const keys = Object.keys(files);
    const tmpFiles = [] as File[];
    keys.forEach((key: any) => tmpFiles.push(files[key]));
    onDrop(tmpFiles);
  }
};

const onDrop = (files: File[] | null) => {
  if (files === null) {
    return;
  }
  const tmpFiles = [] as File[];

  for (const file of files!) {
    if (props.allowedFileType.includes(file.type)) {
      tmpFiles.push(file);
    }
  }

  if (tmpFiles.length) {
    if (props.multiple) {
      emits("fileUpload", tmpFiles);
    } else {
      emits("fileUpload", tmpFiles[0]);
    }

    id.value = Math.random().toString(36);
  }
};

const { isOverDropZone } = useDropZone(inputRef, onDrop);
</script>

<template>
  <div class="h-full w-full">
    <label :for="`file-upload-${id}`">
      <div
        ref="inputRef"
        :key="props.allowedFileType.join()"
        class="h-full w-full cursor-pointer"
      >
        <input
          :id="`file-upload-${id}`"
          :key="id"
          type="file"
          class="hidden"
          :accept="props.allowedFileType.join(', ')"
          :multiple="props.multiple"
          @change="handleFileUpload"
        />
        <slot :is-hover="isOverDropZone"> File Upload </slot>
      </div>
    </label>
  </div>
</template>
