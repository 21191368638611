<script setup lang="ts">
import { ArrowUpTrayIcon, EyeIcon, TrashIcon } from "@heroicons/vue/24/outline";
import { toTypedSchema } from "@vee-validate/zod";
import { z } from "zod";
import { fileToBase64 } from "~/lib/utils/file";

const { t } = useI18n();
const emit = defineEmits(["close", "refresh"]);
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  articleId: {
    type: String,
    default: "new",
  },
  botId: {
    type: String,
    default: "",
  },
  categories: {
    type: Array as PropType<{ name: string; value: string }[]>,
    default: () => [],
  },
  subCategories: {
    type: Array as PropType<{ name: string; value: string }[]>,
    default: () => [],
  },
  disableSync: {
    type: Boolean,
    default: false,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
});

const { $client } = useNuxtApp();

const { data: localModal, refresh } = await $client.wiki.detail.useQuery({
  id: props.articleId,
});

if (props.articleId === "new") {
  const { id } = await $client.wiki.addDraft.mutate({
    botId: props.botId,
  });

  if (localModal.value) localModal.value.id = id;
}

const { data: images, refresh: refreshImages } =
  await $client.wiki.images.list.useQuery({
    wikiId: localModal.value?.id || "",
  });

const { values, defineComponentBinds, validate, meta, resetForm } = useForm({
  initialValues: {
    title: localModal.value?.title || "",
    category: localModal.value?.category || "",
    subCategory: localModal.value?.subCategory || "",
    content: localModal.value?.content || "",
  },
  validationSchema: toTypedSchema(
    z.object({
      title: z.string().nonempty(t("zod.nonempty")),
      category: z.string().nonempty(t("zod.nonempty")),
      subCategory: z.string(),
      content: z.string().nonempty(t("zod.nonempty")),
    })
  ),
});

const title = defineComponentBinds("title", {
  mapProps: (state) => ({
    error: state.errors,
  }),
});
const category = defineComponentBinds("category", {
  mapProps: (state) => ({
    error: state.errors,
  }),
});
const subCategory = defineComponentBinds("subCategory", {
  mapProps: (state) => ({
    error: state.errors,
  }),
});
const content = defineComponentBinds("content", {
  mapProps: (state) => ({
    error: state.errors,
  }),
});

watch(
  () => props.open,
  async () => {
    if (props.open) {
      refreshPermission();
      await refresh();
      resetForm();
    }
  }
);

const localCategories = computed(() => {
  if (props.categories.find((c) => c.value === values.category))
    return props.categories;
  else
    return [
      ...props.categories,
      { name: values.category || "", value: values.category || "" },
    ];
});

const localSubCategories = computed(() => {
  if (props.subCategories.find((c) => c.value === values.subCategory))
    return props.subCategories;
  else
    return [
      ...props.subCategories,
      {
        name: values.subCategory || "",
        value: values.subCategory || "",
      },
    ];
});

const save = async (publish: boolean) => {
  const { valid } = await validate();
  if (!valid) return;
  if (!localModal.value?.id) return;

  await $client.wiki.edit.mutate({
    id: localModal.value.id,
    title: values.title?.trim() || "",
    content: values.content?.trim() || "",
    category: values.category?.trim() || "",
    subCategory: values.subCategory?.trim() || "",
    status: publish ? "PUBLISHED" : "DRAFT",
  });

  emit("refresh");
  emit("close");
};

const addImages = async (files: File[]) => {
  if (!localModal.value?.id) return;

  for await (const file of files) {
    const fileb64 = await fileToBase64(file);

    if (!fileb64) return;
    await $client.wiki.images.add.mutate({
      wikiId: localModal.value.id,
      file: fileb64,
    });
  }

  refreshImages();
};

const deleteImage = async (imageId: string) => {
  if (!localModal.value?.id) return;

  await $client.wiki.images.delete.mutate({
    wikiId: localModal.value.id,
    imageId,
  });

  await refreshImages();
};

const beforeClose = () => {
  if (!localModal.value) {
    emit("close");
    return;
  }

  if (localModal.value.status === "CREATE") {
    $client.wiki.delete.mutate({
      id: localModal.value.id,
    });
  }

  localModal.value.id = "new";
  emit("close");
};

const { data: permission, refresh: refreshPermission } =
  await $client.user.getPermissionScope.useQuery({
    scope: {
      articleEdit: "wdb.edit",
    },
  });
</script>

<template>
  <ModalInnerWrapper
    :open="props.open"
    class="flex h-[90vh] w-[60vw] max-w-[1000px] flex-col"
    @close="beforeClose()"
  >
    <div class="flex h-20 flex-row items-center justify-between px-5">
      <p class="text-xl">
        <template v-if="localModal?.status === 'CREATE'">
          <!--          Artikel erstellen-->
          {{ t("pages.wdb.openArticle.title.create") }}
        </template>
        <template v-if="localModal?.status === 'DRAFT'">
          <!--          Artikel anlegen-->
          {{ t("pages.wdb.openArticle.title.draft") }}
        </template>
        <template v-if="localModal?.status === 'PUBLISHED'">
          <!--          Artikel bearbeiten-->
          {{ t("pages.wdb.openArticle.title.published") }}
        </template>
        <template v-if="localModal?.status === 'ARCHIVED'">
          <!--          Artikel bearbeiten-->
          {{ t("pages.wdb.openArticle.title.archived") }}
        </template>
      </p>
      <div class="flex gap-x-3">
        <template v-if="localModal?.status === 'CREATE'">
          <CustomButton
            type="tertiary"
            :disabled="!permission?.articleEdit"
            @click="save(false)"
          >
            {{ t("pages.wdb.openArticle.action.create") }}
            <!--            Entwurf speichern-->
          </CustomButton>
          <CustomButton
            :type="meta.valid ? 'primary' : 'tertiary'"
            :disabled="!permission?.articleEdit"
            @click="save(true)"
          >
            {{ t("pages.wdb.openArticle.action.default") }}
            <!--            Veröffentlichen-->
          </CustomButton>
        </template>
        <template v-if="localModal?.status === 'DRAFT'">
          <CustomButton
            type="tertiary"
            :disabled="!permission?.articleEdit"
            @click="save(false)"
          >
            {{ t("pages.wdb.openArticle.action.draft") }}
            <!--            Entwurf aktualisieren-->
          </CustomButton>
          <CustomButton
            :type="meta.valid ? 'primary' : 'tertiary'"
            :disabled="!permission?.articleEdit"
            @click="save(true)"
          >
            {{ t("pages.wdb.openArticle.action.default") }}
            <!--            Veröffentlichen-->
          </CustomButton>
        </template>
        <template v-if="localModal?.status === 'PUBLISHED'">
          <CustomButton
            :type="meta.valid ? 'primary' : 'tertiary'"
            :disabled="!permission?.articleEdit"
            @click="save(true)"
          >
            {{ t("pages.wdb.openArticle.action.published") }}
            <!--            Aktualisieren-->
          </CustomButton>
        </template>
        <template v-if="localModal?.status === 'ARCHIVED'">
          <CustomButton
            :type="meta.valid ? 'primary' : 'tertiary'"
            :disabled="!permission?.articleEdit"
            @click="save(true)"
          >
            {{ t("pages.wdb.openArticle.action.default") }}
            <!--            Veröffentlichen-->
          </CustomButton>
        </template>
      </div>
    </div>
    <div class="flex flex-grow flex-col gap-y-4 p-5">
      <InputTextField
        v-bind="title"
        :label="t('pages.wdb.openArticle.inputs.title')"
        autofocus
        :disabled="
          localModal?.status === 'ARCHIVED' || !permission?.articleEdit
        "
      />
      <div class="inline-flex gap-x-3">
        <InputComboBoxField
          v-bind="category"
          :label="t('pages.wdb.openArticle.inputs.category')"
          :options="localCategories"
          :disabled="
            localModal?.status === 'ARCHIVED' || !permission?.articleEdit
          "
        />
        <InputComboBoxField
          v-bind="subCategory"
          :label="t('pages.wdb.openArticle.inputs.subCategory')"
          :options="localSubCategories"
          :disabled="
            localModal?.status === 'ARCHIVED' || !permission?.articleEdit
          "
        />
      </div>
      <InputFakeField
        :model-value="
          localModal?.user?.firstname + ' ' + localModal?.user?.lastname
        "
        :label="t('pages.wdb.openArticle.inputs.author')"
        :disabled="
          localModal?.status === 'ARCHIVED' || !permission?.articleEdit
        "
      />
      <InputBoundingBox
        class="h-fit"
        :label="t('pages.wdb.openArticle.inputs.images')"
        classes="h-fit flex flex-row min-w-0 overflow-x-scroll"
      >
        <div class="flex gap-x-3 p-3">
          <template v-for="image in images" :key="image">
            <!-- <NuxtImg
              width="80"
              height="80"
              :src="image.url"
              fit="cover"
              loading="lazy"
            /> -->
            <div class="relative h-20 w-20 flex-none">
              <img
                :src="image.url"
                alt="article image"
                class="absolute h-20 w-20 flex-none rounded-md object-cover"
                loading="lazy"
              />
              <div
                class="group/image absolute flex h-full w-full flex-col gap-y-1 p-1"
              >
                <CustomButton
                  type="custom"
                  class="flex w-full grow basis-1/2 items-center justify-center rounded-md bg-gray-100/50 p-0 opacity-0 transition-all duration-150 hover:bg-gray-100/70 group-hover/image:opacity-100"
                  classes="p-0"
                  @click="deleteImage(image.id)"
                >
                  <TrashIcon class="h-6 w-6" />
                </CustomButton>
                <NuxtLink
                  :to="image.url"
                  class="flex w-full grow basis-1/2 items-center justify-center rounded-md bg-gray-100/50 p-0 opacity-0 transition-all duration-150 hover:bg-gray-100/70 group-hover/image:opacity-100"
                  classes="p-0"
                  external
                  target="_blank"
                >
                  <EyeIcon class="h-6 w-6" />
                </NuxtLink>
              </div>
            </div>
          </template>
          <UtilsFileInputWrapper
            v-slot="{ isHover }"
            :allowed-file-type="['image/png', 'image/jpeg', 'image/jpg']"
            multiple
            @file-upload="addImages($event)"
          >
            <div
              class="flex h-20 w-20 flex-none items-center justify-center rounded-md hover:bg-alternative"
              :class="{ 'bg-sky-50': isHover }"
            >
              <ArrowUpTrayIcon class="h-6 w-6" />
            </div>
          </UtilsFileInputWrapper>
        </div>
      </InputBoundingBox>
      <InputTextareaField
        v-bind="content"
        :label="t('pages.wdb.openArticle.inputs.content')"
        :disabled="
          localModal?.status === 'ARCHIVED' || !permission?.articleEdit
        "
      />
    </div>
  </ModalInnerWrapper>
</template>
